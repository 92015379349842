'use client'

import { Editor } from '@tinymce/tinymce-react'
import type { Editor as TinyMCEEditor } from 'tinymce/tinymce'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'
import wordsCounter from 'word-counting'
import { style } from './tinyMCEContentStyle'
import { LoadingPlaceholder } from './LoadingPlaceholder'

export interface RichTextEditorProps {
  id?: string
  autoresize?: boolean
  minHeight?: number
  maxHeight?: number
  value: string
  setup?: (editor: TinyMCEEditor) => void
  onChange: (value: string) => void
  error?: boolean
  disabled?: boolean
  errorMinThreshold?: number
  warningMinThreshold?: number
  warningMaxThreshold?: number
  errorMaxThreshold?: number
  editorRef?: MutableRefObject<TinyMCEEditor | null>
}

export function RichTextEditor({
  id,
  errorMinThreshold = 60,
  warningMinThreshold = 75,
  warningMaxThreshold = 100,
  errorMaxThreshold = 120,
  value,
  onChange,
  setup,
  editorRef,
  error = false,
  disabled = false,
  autoresize = true,
  minHeight = 150,
  maxHeight = 600,
}: RichTextEditorProps) {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const firstTimeSet = useRef(true)

  if (typeof value !== 'string') {
    throw new Error('RichGuidedTextField needs a string value to work.')
  }

  const wordsCount = wordsCounter(value, { isHtml: true }).wordsCount

  let color = theme.palette.success.main
  if (wordsCount < errorMinThreshold) {
    color = theme.palette.error.main
  } else if (wordsCount < warningMinThreshold) {
    color = '#FB8441'
  } else if (wordsCount > errorMaxThreshold) {
    color = theme.palette.error.main
  } else if (wordsCount > warningMaxThreshold) {
    color = '#FB8441'
  }

  const plugins: string[] = ['lists', 'table', 'link']
  if (autoresize) {
    plugins.push('autoresize')
  }

  // Close MathType dialog on unmounting
  useEffect(() => {
    return () => {
      const mathTypeDialogElement = document.getElementsByClassName(
        'wrs_modal_dialogContainer'
      )
      Array.from(mathTypeDialogElement).forEach((item) => {
        item.parentNode?.removeChild(item)
      })
    }
  }, [])

  return (
    <Box
      sx={{
        minHeight,
        position: 'relative',
        '& .tox-tinymce': {
          borderRadius: 1,
        },
        ...(error && {
          '& .tox-tinymce': {
            border: 1,
            borderColor: 'red',
          },
        }),
      }}
    >
      {isLoading ? (
        <Box
          sx={{ position: 'absolute', top: 0, left: 0, right: 0, minHeight }}
        >
          <LoadingPlaceholder minHeight={`${minHeight.toString()}px`} />
        </Box>
      ) : null}
      <Editor
        disabled={disabled}
        id={id}
        init={{
          external_plugins: {
            tiny_mce_wiris: `/mathtype-tinymce6/plugin.min.js`,
          },
          menubar: false,
          browser_spellcheck: true,
          contextmenu: false,
          paste_as_text: true,
          plugins: plugins.join(' '),
          object_resizing: 'img',
          toolbar:
            'bold italic | bullist table pblworks_image link | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry | alignleft aligncenter alignright | removeformat',
          draggable_modal: true,
          extended_valid_elements: '*[.*]',
          autoresize_bottom_margin: 20,
          min_height: minHeight,
          max_height: maxHeight,
          statusbar: false,
          setup,
          content_style: style,
          link_default_target: '_blank',
          link_assume_external_targets: true,
          link_target_list: false,
          link_title: false,
        }}
        onEditorChange={(content: string) => {
          if (!firstTimeSet.current) {
            onChange(content)
          }
          firstTimeSet.current = false
        }}
        // eslint-disable-next-line -- unused event
        onInit={(_: any, editor: TinyMCEEditor) => {
          setIsLoading(false)
          if (editorRef) {
            editorRef.current = editor
          }
        }}
        plugins={plugins}
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        value={value}
      />
      {!isLoading && (
        <>
          <Typography variant="caption">Word count: </Typography>
          <Typography color={color} variant="caption">
            {wordsCount}
          </Typography>
          <Typography variant="caption">
            {' '}
            (Ideal: {warningMinThreshold}-{warningMaxThreshold})
          </Typography>
        </>
      )}
    </Box>
  )
}
